<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 v-if="!loading" class="fade-in">File A Claim</h2>
        </div>
          <div class="content">
            <div v-if="!loading" class="fade-in">
              <div class="section-head">
                <h3>Loss Details</h3>
              </div>
              <div class="rows">
                <div class="row">
                  <div class="data-field fourth">
                    <label :class="form.dateOfInjury.error ? 'error-text' : ''"><span class="red">*</span>Date of
                      Injury</label>
                    <input type="datetime-local" placeholder="" v-model="form.dateOfInjury.value"
                      :class="form.dateOfInjury.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.dateOfInjury)" />
                  </div>
                  <div class="data-field fourth">
                    <label :class="form.claimType.error ? 'error-text' : ''"><span class="red">*</span>Claim
                      Type</label>
                    <select v-model="form.claimType.value" v-on:change="handleValueChange(form.claimType)"
                      :class="form.claimType.error ? 'error-element' : ''">
                      <option value=null disabled selected hidden>
                        Select Claim Type
                      </option>
                      <option v-for="type in data.claimTypes" :key="type.ID" :value="type.ID">
                        {{ type.typeDescription }}
                      </option>
                    </select>
                  </div>
                  <div class="data-field">
                    <div class="check-box checkbox-label">
                      <input type="checkbox" v-model="form.notifyPrimaryContact.value" class="chkBox" />
                      <label><strong>Check here to send confirmation email to
                          Primary Claim Contact.</strong></label>
                    </div>
                  </div>
                  <div class="data-field">
                    <div class="check-box checkbox-label">
                      <input type="checkbox" v-model="form.isFEAPayrollModel.value" class="chkBox" @change="showModal(data)" />
                      <label v-if="form.isFEAPayrollModel.value"><strong><a class="modal-link" @click="showModal(data)">Financial Management Services Model (FMS)</a></strong></label>
                      <label v-else><strong>Financial Management Services Model (FMS)</strong></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="data-field half">
                    <label :class="form.lossWhere.error ? 'error-text' : ''"><span class="red">*</span>Tell us where the
                      event occurred
                      (e.g. Loading dock north end)</label>
                    <textarea type="text" placeholder="Enter Description" v-model="form.lossWhere.value"
                      v-on:change="handleValueChange(form.lossWhere)"
                      :class="form.lossWhere.error ? 'error-element' : ''" :maxlength="form.lossWhere.maxlength" />
                  </div>
                  <div class="data-field half">
                    <label :class="form.lossHow.error ? 'error-text' : ''"><span class="red">*</span>Tell us how the
                      injury occurred
                      and the details before the incident</label>
                    <textarea type="text" placeholder="Enter Description" v-model="form.lossHow.value"
                      v-on:change="handleValueChange(form.lossHow)" :class="form.lossHow.error ? 'error-element' : ''"
                      :maxlength="form.lossHow.maxlength" />
                  </div>
                </div>
                <div class="row">
                  <div class="data-field half">
                    <label :class="form.injuryDetails.error ? 'error-text' : ''"><span class="red">*</span>What was the
                      injury or illness
                      (include the part(s) of the body)?</label>
                    <textarea type="text" placeholder="Enter Description" v-model="form.injuryDetails.value"
                      v-on:change="handleValueChange(form.injuryDetails)"
                      :class="form.injuryDetails.error ? 'error-element' : ''"
                      :maxlength="form.injuryDetails.maxlength" />
                  </div>
                  <div class="data-field half">
                    <label :class="form.injuryObjectsInvolved.error ? 'error-text' : ''"><span class="red">*</span>What tools, equipment, machines,
                      objects or substances were involved?</label>
                    <textarea type="text" placeholder="Enter Description" v-model="form.injuryObjectsInvolved.value"
                      v-on:change="handleValueChange(form.injuryObjectsInvolved)"
                      :class="form.injuryObjectsInvolved.error ? 'error-element' : ''"
                      :maxlength="form.injuryObjectsInvolved.maxlength"  />
                  </div>
                </div>
                <div class="row">
                  <div class="data-field fourth">
                    <label :class="form.assignedAddress1.error ? 'error-text' : ''"><span class="red">*</span>Employee
                      Assigned Address 1</label>
                    <input type="text" placeholder="Enter Address Line 1" v-model="form.assignedAddress1.value"
                      :class="form.assignedAddress1.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.assignedAddress1)"
                      :maxlength="form.assignedAddress1.maxlength" />
                  </div>
                  <div class="data-field fourth">
                    <label>Employee Assigned Address 2</label>
                    <input type="text" placeholder="Apartment, suite, building, unit, floor, etc."
                      v-model="form.assignedAddress2.value" :maxlength="form.assignedAddress2.maxlength" />
                  </div>
                  <div class="data-field sixth">
                    <label :class="form.assignedCity.error ? 'error-text' : ''"><span class="red">*</span>Employee
                      Assigned City</label>
                    <input type="text" placeholder="Enter City" v-model="form.assignedCity.value"
                      :class="form.assignedCity.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.assignedCity)" :maxlength="form.assignedCity.maxlength" />
                  </div>
                  <div class="data-field sixth">
                    <label :class="form.assignedState.error ? 'error-text' : ''"><span class="red">*</span>Employee
                      Assigned State</label>
                    <select v-model="form.assignedState.value" :class="form.assignedState.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.assignedState)">
                      <option value=null disabled selected hidden>
                        Select State
                      </option>
                      <option v-for="state in data.states" :key="state.ID" :value="state.stateCode">
                        {{ state.stateName }}
                      </option>
                    </select>
                  </div>
                  <div class="data-field sixth">
                    <label :class="form.assignedZip.error ? 'error-text' : ''"><span class="red">*</span>Employee
                      Assigned ZIP Code</label>
                    <input type="number" placeholder="Enter Zip Code"
                      :class="form.assignedZip.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.assignedZip)" :value="form.assignedZip.display" v-on:input="(e) => {
                        e.target.value = numeric(e.target.value, form.assignedZip)
                      }" />
                  </div>
                </div>
                <div class="row">
                  <div class="radio-group half">
                    <label class="bold" :class="form.injuryOnPremises.error ? 'error-text' : ''"><span
                        class="red">*</span>Did injury occur on employer’s
                      premises?</label>
                    <div class="radio-options half">
                      <div class="radio-label">
                        <input type="radio" v-model="form.injuryOnPremises.value" name="injuryOnPremises" :value="true"
                          v-on:change="handleValueChange(form.injuryOnPremises)" />
                        <label>Yes</label>
                      </div>
                      <div class="radio-label">
                        <input type="radio" v-model="form.injuryOnPremises.value" name="injuryOnPremises" :value="false"
                          v-on:change="handleValueChange(form.injuryOnPremises)" />
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                  <div class="radio-group half">
                    <label class="bold" :class="form.paidOnDOI.error ? 'error-text' : ''"><span
                        class="red">*</span>Employers paid for lost time on
                      day of injury (DOI)?</label>
                    <div class="radio-options">
                      <div class="radio-label">
                        <input type="radio" v-model="form.paidOnDOI.value" name="paidOnDOI" :value="'1'"
                          v-on:change="handleValueChange(form.paidOnDOI)" />
                        <label>Yes</label>
                      </div>
                      <div class="radio-label">
                        <input type="radio" v-model="form.paidOnDOI.value" name="paidOnDOI" :value="'0'"
                          v-on:change="handleValueChange(form.paidOnDOI)" />
                        <label>No</label>
                      </div>
                      <div class="radio-label">
                        <input type="radio" v-model="form.paidOnDOI.value" name="paidOnDOI" :value="'2'"
                          v-on:change="handleValueChange(form.paidOnDOI)" />
                        <label>No lost time on DOI</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="data-field fourth">
                    <label :class="form.beganWorkTime.error ? 'error-text' : ''"><span class="red">*</span>Time employee
                      began work</label>
                    <input type="datetime-local" placeholder="" v-model="form.beganWorkTime.value"
                      v-on:change="handleValueChange(form.beganWorkTime)" />
                  </div>
                  <div class="data-field fourth">
                    <label>Date of first day of any lost time</label>
                    <input type="date" placeholder="" v-model="form.dateFirstDayLost.value" />
                  </div>
                  <div class="data-field fourth">
                    <label :class="form.dateInjuryNotified.error ? 'error-text' : ''"><span class="red">*</span>Date
                      employer notified of
                      injury</label>
                    <input type="date" placeholder="" v-model="form.dateInjuryNotified.value"
                      v-on:change="handleValueChange(form.dateInjuryNotified)" :class="
                        form.dateInjuryNotified.error ? 'error-element' : ''
                      " />
                  </div>
                  <div class="data-field fourth">
                    <label>Date employer notified of lost time</label>
                    <input type="date" placeholder="" v-model="form.dateTimeNotified.value" />
                  </div>
                </div>
                <div class="row">
                  <div class="data-field fourth">
                    <label>Return to work date</label>
                    <input type="date" placeholder="" v-model="form.dateWorkReturn.value" />
                  </div>
                  <div class="data-field fourth">
                    <label>Date of death</label>
                    <input type="date" placeholder="" v-model="form.dateOfDeath.value" />
                  </div>
                  <div class="radio-group fourth">
                    <label class="bold">RTW Same Employer:</label>
                    <div class="radio-options half">
                      <div class="radio-label">
                        <input type="radio" name="rtwSameEmployer" :value="true" v-model="form.rtwSameEmployer.value" />
                        <label>Yes</label>
                      </div>
                      <div class="radio-label">
                        <input type="radio" name="rtwSameEmployer" :value="false"
                          v-model="form.rtwSameEmployer.value" />
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                  <div class="radio-group fourth">
                    <label class="bold">RTW With Restrictions:</label>
                    <div class="radio-options half">
                      <div class="radio-label">
                        <input type="radio" name="rtwWithRestrictions" :value="true"
                          v-model="form.rtwWithRestrictions.value" />
                        <label>Yes</label>
                      </div>
                      <div class="radio-label">
                        <input type="radio" name="rtwWithRestrictions" :value="false"
                          v-model="form.rtwWithRestrictions.value" />
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-head">
                <h3>
                  Other Information
                </h3>
              </div>
              <div class="rows">
                <div class="row">
                  <div class="data-field half">
                    <label :class="form.treatingPhysician.error ? 'error-text' : ''"><span class="red">*</span>Treating
                      Physician (name,
                      address, and phone)</label>
                    <textarea type="text" placeholder="Enter Information" v-model="form.treatingPhysician.value"
                      v-on:change="handleValueChange(form.treatingPhysician)"
                      :class="form.treatingPhysician.error ? 'error-element' : ''"
                      :maxlength="form.treatingPhysician.maxlength" />
                  </div>
                  <div class="data-field half">
                    <label :class="form.hospitalOrClinic.error ? 'error-text' : ''"><span
                        class="red">*</span>Hospital/Clinic (name and
                      address)</label>
                    <textarea type="text" placeholder="Enter Information" v-model="form.hospitalOrClinic.value"
                      v-on:change="handleValueChange(form.hospitalOrClinic)"
                      :class="form.hospitalOrClinic.error ? 'error-element' : ''"
                      :maxlength="form.hospitalOrClinic.maxlength" />
                  </div>
                </div>
                <div style="padding-left: 10px">
                  <label class="fourth" style="width: 100%" :class="
                    form.extentOfMedicalAnswered.error ? 'error-text' : ''
                  "><span class="red">*</span>Extent of Medical Treatment (check
                    all that apply):</label>
                </div>
                <div class="row">
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendNone.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>None</label>
                  </div>
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendOnSite.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>Minor on-site by employer’s medical staff</label>
                  </div>
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendMinor.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>Minor clinic/hospital</label>
                  </div>
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendEmergency.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>Emergency Room</label>
                  </div>
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendHospitalized.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>Hospitalization more than 24 hours</label>
                  </div>
                  <div class="check-box checkbox-label">
                    <input type="checkbox" v-model="form.extendFutureMajor.value" class="chkBox"
                      v-on:change="handleChecks()" />
                    <label>Future major medical anticipated</label>
                  </div>
                </div>
                <div class="row">
                  <div class="data-field fifth">
                    <label>Unemployment ID</label>
                    <input type="text" placeholder="Enter ID" v-model="form.unemploymentID.value"
                      :maxlength="form.unemploymentID.maxlength" />
                  </div>
                  <div class="data-field fifth">
                    <label>NAICS</label>
                    <input type="text" placeholder="Enter NAICS" v-model="form.claimantNAICS.value"
                      :maxlength="form.claimantNAICS.maxlength" />
                  </div>
                  <div class="data-field fifth">
                    <label>Witness Name</label>
                    <input type="text" placeholder="Enter Witness Name" v-model="form.witnessName.value"
                      :maxlength="form.witnessName.maxlength" />
                  </div>
                  <div class="data-field fifth">
                    <label>Witness Phone</label>
                    <input type="text" :value="form.witnessPhone.display" placeholder="" maxlength="14" v-on:input="
                      (e) => {
                        phoneMaskFormat(
                          e.target.value,
                          form.witnessPhone
                        );
                      }
                    " />
                  </div>
                  <div class="data-field fifth">
                    <label>Date insurer received notice</label>
                    <input type="date" placeholder="" v-model="form.dateInsurerNotice.value"
                      v-on:change="handleValueChange(form.dateInsurerNotice)" />
                  </div>
                </div>
              </div>
              <div class="ldBtns">
                <button class="primary back" v-on:click="handlePrevious()">Previous</button>
                <button class="primary" v-on:click="continueClaim()" type="button">
                  Continue
                </button>
              </div>
            </div>
          </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import {
  getLossDetailsData,
  saveLossDetailsFROI,
  saveToken,
  getDecodedToken
} from "../../Services/claimsService";
import utilities from "../../Services/utilities";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
export default {
  data() {
    return {
      form: {
        dateOfInjury: {
          required: true,
          type: "datetime-local",
          value: null,
          error: false,
        },
        claimType: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        lossWhere: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        lossHow: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryDetails: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryObjectsInvolved: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        assignedAddress1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedAddress2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedCity: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        assignedState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        assignedZip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        injuryOnPremises: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        paidOnDOI: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        beganWorkTime: {
          required: true,
          type: "datetime-local",
          value: null,
          display: null,
          error: false,
        },
        dateFirstDayLost: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        dateInjuryNotified: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateTimeNotified: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        treatingPhysician: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "500"
        },
        hospitalOrClinic: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "500"
        },
        dateInsurerNotice: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        extentOfMedicalAnswered: {
          required: true,
          type: "group",
          value: null,
          error: false,
        },
        extendNone: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        extendOnSite: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        extendMinor: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        extendEmergency: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        extendHospitalized: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        extendFutureMajor: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        witnessPhone: {
          required: false,
          type: "phone",
          value: null,
          error: false,
        },
        notifyPrimaryContact: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        isFEAPayrollModel: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        feapm_FirstName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        feapm_LastName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        feapm_FEIN: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "12"
        },
        feapm_Address1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        feapm_Address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        feapm_City: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        feapm_State: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2"
        },
        feapm_Zip: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "5"
        },
        dateWorkReturn: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        dateOfDeath: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        rtwSameEmployer: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        rtwWithRestrictions: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        unemploymentID: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        claimantNAICS: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        witnessName: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
      },
      data: {
        claimTypes: [],
      },
      loading: true
    };
  },
  components: { BaseClaimLayout },
  props: ["lineCode", "policyID"],
  methods: {
    showModal(data) {
      if(this.form.isFEAPayrollModel.value) {
        let stateOptions = '';
        for (const state of data.states) {
          let selected = '';
          if (state.stateCode === this.form.feapm_State.value) {
            selected = 'selected'
          }
          stateOptions += `<option :key="${state.ID}" value="${state.stateCode}" ${selected}>${state.stateName}</option>`;
        };
        const fName = this.form.feapm_FirstName.value || '';
        const lName = this.form.feapm_LastName.value || '';
        const fein = this.form.feapm_FEIN.value || '';
        const add1 = this.form.feapm_Address1.value || '';
        const add2 = this.form.feapm_Address2.value || '';
        const city = this.form.feapm_City.value || '';
        const zip = this.form.feapm_Zip.value || '';
        this.$swal({
          width: 800,
          html:
            `<h3 class="content-box-header bg-primary"><span style="padding:10px;" class="section-head">Financial Management Services Model (FMS) Entry</span></h3>
            <div>
              <table>
                <tr>
                  <td><label id="feapm_FirstName_label"><span class="red">*</span>First Name</label></td>
                  <td><label id="feapm_LastName_label"><span class="red">*</span>Last Name</label></td>
                  <td><label id="feapm_FEIN_label"><span class="red">*</span>FEIN</label></td>
                </tr>
                <tr>
                  <td>
                    <input id="feapm_FirstName" value="${fName}" type="text" placeholder="Enter first name..." v-model="form.feapm_FirstName.value"
                    class="${this.form.feapm_FirstName.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_FirstName.maxlength}" style="width:100%;" />
                  </td>
                  <td>
                    <input id="feapm_LastName" value="${lName}" type="text" placeholder="Enter last name..." v-model="form.feapm_LastName.value"
                    class="${this.form.feapm_LastName.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_LastName.maxlength}" style="width:100%;" />
                  </td>
                  <td>
                    <input id="feapm_FEIN" value="${fein}" type="text" placeholder="Enter FEIN..." v-model="form.feapm_FEIN.value"
                    class="${this.form.feapm_FEIN.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_FEIN.maxlength}" style="width:100%;" />
                  </td>
                </tr>
                <tr>
                  <td><label id="feapm_Address1_label"><span class="red">*</span>Address 1</label></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <input id="feapm_Address1" value="${add1}" type="text" placeholder="Enter primary address..." v-model="form.feapm_Address1.value"
                    class="${this.form.feapm_Address1.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_Address1.maxlength}" style="width:100%;" />
                  </td>
                </tr>
                <tr>
                  <td><label>Address 2</label></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <input id="feapm_Address2" value="${add2}" type="text" placeholder="Enter secondary address..." v-model="form.feapm_Address2.value"
                    class="${this.form.feapm_Address2.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_Address2.maxlength}" style="width:100%;" />
                  </td>
                </tr>
                <tr>
                  <td><label id="feapm_City_label"><span class="red">*</span>City</label></td>
                  <td><label id="feapm_State_label"><span class="red">*</span>State</label></td>
                  <td><label id="feapm_Zip_label"><span class="red">*</span>ZIP Code</label></td>
                </tr>
                <tr>
                  <td>
                    <input id="feapm_City" value="${city}" type="text" placeholder="Enter city..." v-model="form.feapm_City.value"
                    class="${this.form.feapm_City.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_City.maxlength}" style="width:100%;" />
                  </td>
                  <td>
                    <select id="feapm_State" v-model="form.feapm_State.value" :class="${this.form.feapm_State.error ? 'error-element' : ''}"
                        v-on:change="handleValueChange(form.feapm_State)" style="width:100%;">
                        <option value=null disabled selected hidden>
                          Select State
                        </option>
                        ${stateOptions}
                      </select>
                  </td>
                  <td>
                    <input id="feapm_Zip" value="${zip}" type="text" placeholder="Enter ZIP Code..." v-model="form.feapm_Zip.value"
                    class="${this.form.feapm_Zip.error ? 'error-element' : ''}"
                    maxlength="${this.form.feapm_Zip.maxlength}" style="width:100%;" />
                  </td>
                </tr>
              </table>
            </div>
            `,
          confirmButtonText: 'Continue',
          confirmButtonColor: '#2b8cbe',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          allowOutsideClick: false,
          focusConfirm: false,
          preConfirm: () => {
            let e = document.getElementById('feapm_State');
            this.form.feapm_FirstName.value = document.getElementById('feapm_FirstName').value,
            this.form.feapm_LastName.value = document.getElementById('feapm_LastName').value,
            this.form.feapm_FEIN.value = document.getElementById('feapm_FEIN').value,
            this.form.feapm_Address1.value = document.getElementById('feapm_Address1').value,
            this.form.feapm_Address2.value = document.getElementById('feapm_Address2').value,
            this.form.feapm_City.value = document.getElementById('feapm_City').value,
            this.form.feapm_State.value = e.options[e.selectedIndex].value,
            this.form.feapm_Zip.value = document.getElementById('feapm_Zip').value
            const modalForm = {
              feapm_FirstName: this.form.feapm_FirstName,
              feapm_LastName: this.form.feapm_LastName,
              feapm_FEIN: this.form.feapm_FEIN,
              feapm_Address1: this.form.feapm_Address1,
              feapm_City: this.form.feapm_City,
              feapm_State: this.form.feapm_State,
              feapm_Zip: this.form.feapm_Zip
            };
            const valid = utilities.validateFields(modalForm);
            if (valid) {
              return [
                this.form.feapm_FirstName.value = document.getElementById('feapm_FirstName').value,
                this.form.feapm_LastName.value = document.getElementById('feapm_LastName').value,
                this.form.feapm_FEIN.value = document.getElementById('feapm_FEIN').value,
                this.form.feapm_Address1.value = document.getElementById('feapm_Address1').value,
                this.form.feapm_Address2.value = document.getElementById('feapm_Address2').value,
                this.form.feapm_City.value = document.getElementById('feapm_City').value,
                this.form.feapm_State.value = e.options[e.selectedIndex].value,
                this.form.feapm_Zip.value = document.getElementById('feapm_Zip').value
              ]
            } else {
              Object.keys(modalForm).forEach((key) => {
                const field = modalForm[key];
                const valid = !field.error;
                if (!valid) {
                  document.getElementById(`${key}_label`).classList.add('error-text');
                  document.getElementById(`${key}`).classList.add('error-element');
                } else {
                  document.getElementById(`${key}_label`).classList.remove('error-text');
                  document.getElementById(`${key}`).classList.remove('error-element');
                }
              });
              this.$swal.showValidationMessage(
                `Please fill in all required fields`
              )
            }
          }
        });
      }
    },
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getLossDetailsData(this);
          const data = res.data;

          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
            this.$swal.fire({
              icon: "error",
              title: "Error!",
              text: "Could not fetch the loss details.",
              confirmButtonColor: "#2b8cbe",
            });
          } else {

            const decoded = await getDecodedToken();

            const screenInfo = decoded.MNWCLossDetailsScreenInfo;
            if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
              this.form = screenInfo;
            } else {
              if (data.lossDetailsData && data.fnolData) {
                utilities.handleFormData(data.lossDetailsData, this.form);
                utilities.handleFormData(data.fnolData, this.form);
                utilities.handleFormData({ extentOfMedicalAnswered: true }, this.form);
              }
            }

            this.loading = false;
            this.data = data;
            this.$swal.close();
          }

        },
      });
    },
    handleValueChange: utilities.handleValueChange,
    phoneMaskFormat: utilities.phoneMaskFormat,
    numeric: utilities.numeric,
    handleChecks() {
      let answered = false;
      [
        this.form.extendNone.value,
        this.form.extendOnSite.value,
        this.form.extendMinor.value,
        this.form.extendEmergency.value,
        this.form.extendHospitalized.value,
        this.form.extendFutureMajor.value,
      ].forEach((val) => {
        if (val === true) answered = true;
      });
      this.form.extentOfMedicalAnswered.value = answered;
      this.form.extentOfMedicalAnswered.error = !answered;
    },
    async saveInformation() {
      await saveToken({MNWCLossDetailsScreenInfo: this.form}, this);
    },
    async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
        name: "MakeClaim",
        params: {},
      });
    },
    async continueClaim() {

      this.form.extentOfMedicalAnswered.error = false;
      if (!this.form.isFEAPayrollModel.value) {
        this.form.feapm_FirstName.required = false;
        this.form.feapm_LastName.required = false;
        this.form.feapm_FEIN.required = false;
        this.form.feapm_Address1.required = false;
        this.form.feapm_City.required = false;
        this.form.feapm_State.required = false;
        this.form.feapm_Zip.required = false;
      }
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
          title: "Loading...",
          // timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          didOpen: async () => {
            let time = new Date().getTime();
            this.$swal.showLoading();

            const res = await saveLossDetailsFROI(this.form, "MN", this);
            const srvData = res.data;

            time = new Date().getTime() - time;

            if (time < 1000) {
              await utilities.wait(1000 - time);
            }

            if (!srvData || !srvData.success) {
              utilities.showFailureAlert(this, "Could not save the loss details.");
            } else {
              await saveToken({MNWCLossDetailsScreenInfo: null}, this)
              await utilities.showSuccessAlert(this, "Loss details saved successsfully.");
              this.$router.push({
                name: "WorkersCompClaimantInformation",
                params: {},
              });
            }

          },
        });
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
#wrapper {
  top: 0%;
}

input,
select {
  height: 40px;
}

.checkbox-label {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.chkBox {
  margin-right: 10px;
  width: 15px;
}

#loginButton {
  float: right;
  margin-right: 20px;
}

.check-box {
  min-height: 60px;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.check-box input {
  margin-right: 10px;
}

header {
  background-color: white;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}

.modal-link {
  text-decoration: underline;
  cursor: pointer;
  color: #2b8cbe;
}
</style>